.profilePic {
  height: 20vmin;
  pointer-events: none;
  border-radius: 50%;
}

.header {
  background-color: #1a281a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: 28px;
  text-decoration: underline;
}

.traits {
  font-size: 16px;
  list-style-type: "/ ";
}

.traits li {
  line-height: 1.5em;
  padding: 8px 0;
}

.code {
  font-family: menlo, monospace;
  font-size: 16px;
  text-align: left;
}

a {
  color: inherit;
  text-decoration: none
}

.initial {
  font-weight: bold;
}

.mail-to {
  font-size: 12px;
}

.mail-link {
  text-decoration: underline;
}